<template>
    <div> 
        <div class="module deals-layout1">
            <div class="head-title">
                <div class="modtitle">
                    <span>Trending Categories</span>
                </div>    
            </div>
            <div class="modcontent">
                <div id="so_deal_1" class="so-deal style1">
                    <div class="extraslider-inner products-list yt-content-slider" data-rtl="yes" data-autoplay="no" data-autoheight="no" data-delay="4" data-speed="0.6" data-margin="30" data-items_column00="3" data-items_column0="5" data-items_column1="3" data-items_column2="2"  data-items_column3="2" data-items_column4="1" data-arrows="yes" data-pagination="no" data-lazyload="yes" data-loop="yes" data-hoverpause="yes">
                        <div  v-for="(item,index) in data.blockdata" :key="index">
                            <component  :is="item.blockdatacomponent" v-bind:data="item" ></component>
                        </div>
                        <!-- <div class="item" v-for="index in 10" :key="index">
                            <div class="row panel panel-default" style="padding:15px 15px 5px 15px; margin:0px;">
                                <div class="col-md-12 panel panel-default">
                                    <div class="row">
                                        <div class="col-md-2"><h5>Sub Cat {{index}}</h5></div>
                                        <div class="col-md-5"><img src="assets/image/catalog/demo/product/270/h2.jpg" class="img-responsive img-rounded"></div>
                                        <div class="col-md-5"><img src="assets/image/catalog/demo/product/270/h3.jpg" class="img-responsive img-rounded"></div>
                                    </div>
                                </div>
                                <div class="col-md-12 panel panel-default">
                                    <div class="row">
                                        <div class="col-md-2"><h5>Sub Cat {{index}}</h5></div>
                                        <div class="col-md-5"><img src="assets/image/catalog/demo/product/270/h2.jpg" class="img-responsive img-rounded"></div>
                                        <div class="col-md-5"><img src="assets/image/catalog/demo/product/270/h3.jpg" class="img-responsive img-rounded"></div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductTile from '@/components/product/product_tile.vue'
import ProductTileGrid from '@/components/product/product_tile_grid.vue'

export default {
name: 'ProductSliderGridBlock',
props: {
    data: {
      type: Object
    },
  },
components: { 
    ProductTile,
    ProductTileGrid
     },
beforemounted() {
    this.initiateSlider();
},
methods: {
    initiateSlider() {
        $('.yt-content-slider').each(function () {
            var $slider = $(this),
                $panels = $slider.children('div'),
                data = $slider.data();
            // Remove unwanted br's
            //$slider.children(':not(.yt-content-slide)').remove();
            // Apply Owl Carousel
            
            $slider.owlCarousel2({
                responsiveClass: true,
                mouseDrag: true,
                video:true,
                lazyLoad: (data.lazyload == 'yes') ? true : false,
                autoplay: (data.autoplay == 'yes') ? true : false,
                autoHeight: (data.autoheight == 'yes') ? true : false,
                autoplayTimeout: data.delay * 1000,
                smartSpeed: data.speed * 1000,
                autoplayHoverPause: (data.hoverpause == 'yes') ? true : false,
                center: (data.center == 'yes') ? true : false,
                loop: (data.loop == 'yes') ? true : false,
                dots: (data.pagination == 'yes') ? true : false,
                nav: (data.arrows == 'yes') ? true : false,
                dotClass: "owl2-dot",
                dotsClass: "owl2-dots",
                margin: data.margin,
                navText: ['',''],
                
                responsive: {
                    0: {
                        items: data.items_column4 
                        },
                    480: {
                        items: data.items_column3
                        },
                    768: {
                        items: data.items_column2
                        },
                    992: { 
                        items: data.items_column1
                        },
                    1200: {
                        items: data.items_column0 
                        },
                    1650: {
                        items: data.items_column00 
                    }
                }
            });
            
        });
    },
    viewProduct:function(){
        this.$router.push('product');
    }
  }


}
</script>

