<template>
<div>
  <div class="static-cates">
      <ul>
        <span v-for="(item, index) in data.blockdata" :key="index">
          <li>
              <a @click="viewProductList(item.item_id)"><img :src="item.imgpath" alt="image"></a>
          </li>
        </span>           
      </ul>
  </div>
</div>
</template>
<script> 
export default {
name: 'StaticCates',
props: {
    data: {
      type: Object
    },
  },
components: { },
methods: {
    quickViewProductModal: function () {
      alert('View Product Popup Modal...')
    //   this.$refs.modal.show()
    //   $('#myModal').modal('show');
    },
    viewProduct:function(item_id){ 
        this.$router.push({ path: 'product', query: { pid: item_id } })
    },
    viewProductList:function(item_id){ 
        this.$router.push({ path: 'productlist', query: { cid: item_id } })
    }
  }


}
</script>

