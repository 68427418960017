<template>
<div>
    <div class="banners3 banners">
        <div :class="item.class" v-for="(item, index) in data.blockdata" :key="index">
            <a :href="item.url" target="_blank"><img :src="item.imgpath" alt="image"></a>
        </div> 
    </div>  
</div>
</template>
<script> 
export default {
name: 'Banner3',
props: {
    data: {
      type: Object
    },
  },
components: {},
methods: {
    quickViewProductModal: function () {
      alert('View Product Popup Modal...')
    //   this.$refs.modal.show()
    //   $('#myModal').modal('show');
    },
    viewProduct:function(item_id){ 
        this.$router.push({ path: 'product', query: { pid: item_id } })
    }
  }


}
</script>

