<template>
<div>
  <div class="banners4 banners">
     <div class="module deals-layout1" v-if="data.title"> 
            <div class="head-title">
                <div class="modtitle">
                    <span>{{data.title}}</span>
                </div>    
            </div>       
        </div>
      <div class="row"> 
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" v-for="(item, index) in data.blockdata" :key="index">
              <a :href="item.url" target="_blank"><img :src="item.imgpath" alt="image" style="width:100%;"></a>
          </div> 
      </div>
  </div>
</div>
</template>
<script> 
export default {
name: 'Banner4',
props: {
    data: {
      type: Object
    },
  },
components: { },
methods: {
    quickViewProductModal: function () {
      alert('View Product Popup Modal...')
    //   this.$refs.modal.show()
    //   $('#myModal').modal('show');
    },
    viewProduct:function(item_id){ 
        this.$router.push({ path: 'product', query: { pid: item_id } })
    }
  }


}
</script>

