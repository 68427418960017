<template>
  <div v-if="data.showBlock">
    <div class="module deals-layout1">
      <div class="cslider-item-timer title-category" v-if="data.deal_start">
        <div
          class="product_time_maxprice"
          style="font-size: 2.6rem; font-weight: 700"
        >
          <div class="item-time">
            <div class="item-timer">
              <div class="defaultCountdown-30">
                <div class="time-item time-day" style="margin-left: 0px">
                  <div class="num-time">Flash Deal Ends in</div>
                  <div class="name-time">Day</div>
                </div>
                <div class="time-item time-hour">
                  <div class="num-time">{{ data.dealHours.toFixed(0) }} Hour</div>
                  <div class="name-time">Hour</div>
                </div>
                <div class="time-item time-min">
                  <div class="num-time">
                    {{ data.dealMinutes.split(".")[0] }} Min
                  </div>
                  <div class="name-time">Min</div>
                </div>
                <div class="time-item time-sec">
                  <div class="num-time">{{ data.dealSeconds.toFixed(0) }} Sec</div>
                  <div class="name-time">Sec</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: end; font-size: small" class="desktopflsh-viewall">
          <a @click="haddleFlashDeal()">View All</a>
        </div>
        <div class="mobileflsh-viewall"><a @click="haddleFlashDeal()">View All</a></div>
      </div>
      
      <h2 v-if="!data.deal_start">{{ data.title }}</h2>
    </div>
    <div class="modcontent">
      <div id="so_deal_1" class="so-deal style1">
        <div
          class="extraslider-inner products-list yt-content-slider"
          data-rtl="yes"
          data-autoplay="yes"
          data-autoheight="no"
          data-delay="4"
          data-speed="0.6"
          data-margin="30"
          data-items_column00="6"
          data-items_column0="5"
          data-items_column1="3"
          data-items_column2="2"
          data-items_column3="2"
          data-items_column4="1"
          data-arrows="yes"
          data-pagination="no"
          data-lazyload="yes"
          data-loop="yes"
          data-hoverpause="yes"
        >
          <div
            id="productSliderContainer"
            v-for="(item, index) in data.blockdata"
            :key="index"
          >
            <component
              :is="item.blockdatacomponent"
              v-bind:data="item"
            ></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductTile from "@/components/product/product_tile_new.vue";
import ProductTileDeal from "@/components/product/product_tile_deal.vue";
import ProductTileBrand from "@/components/product/product_tile_brand.vue";
import ProductTileGrid from "@/components/product/product_tile_grid.vue";

export default {
  name: "ProductSliderBlock",
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    ProductTile,
    ProductTileBrand,
    ProductTileGrid,
    ProductTileDeal,
  },
  data: function () {
    return {
      dealHours: 0,
      dealMinutes: "0.0",
      dealSeconds: 0,
      showblock: true,
    };
  },
  beforemounted() {
    this.initiateSlider();
    this.initate();
  },
  methods: {
    initate() {
      if (this.data.deal_start) {
        // this.dealTime(this.data.deal_start, this.data.deal_end);
      }
    },
    dealTime(startdate, dateend) {
      var startDate = new Date(startdate);
      // Do your operations
      var endDate = new Date(dateend);
      var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
      clearInterval(homdealtimer);
      if (seconds <= 0) {
        this.dealHours = 0;
        this.dealMinutes = "0.0";
        this.dealSeconds = 0;
        return;
      }
      let self = this;
      var homdealtimer = setInterval(function () {
        seconds = seconds - 1;
        if (self.$route.name != "Buyer Home") {
          clearInterval(homdealtimer);
        }
        if (seconds <= 0) {
          clearInterval(homdealtimer);
          return;
        }

        // console.log(seconds);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(seconds / 3600);
        var textseconds = seconds - minutes * 60;
        var textminutes = String((seconds % 3600) / 60);
        self.dealHours = hours;
        self.dealMinutes = textminutes;
        self.dealSeconds = textseconds;
        // console.log(hours + ' : ' + textminutes + ' : ' + textseconds);
      }, 1000);
    },
    initiateSlider() {
      $(".yt-content-slider").each(function () {
        var $slider = $(this),
          $panels = $slider.children("div"),
          data = $slider.data();
        // Remove unwanted br's
        //$slider.children(':not(.yt-content-slide)').remove();
        // Apply Owl Carousel

        $slider.owlCarousel2({
          responsiveClass: true,
          mouseDrag: true,
          video: true,
          lazyLoad: data.lazyload == "yes" ? true : false,
          autoplay: data.autoplay == "yes" ? true : false,
          autoHeight: data.autoheight == "yes" ? true : false,
          autoplayTimeout: data.delay * 1000,
          smartSpeed: data.speed * 1000,
          autoplayHoverPause: data.hoverpause == "yes" ? true : false,
          center: data.center == "yes" ? true : false,
          loop: false,
          dots: data.pagination == "yes" ? true : false,
          nav: data.arrows == "yes" ? true : false,
          dotClass: "owl2-dot",
          dotsClass: "owl2-dots",
          margin: data.margin,
          navText: ["", ""],

          responsive: {
            0: {
              items: data.items_column4,
            },
            480: {
              items: data.items_column3,
            },
            768: {
              items: data.items_column2,
            },
            992: {
              items: data.items_column1,
            },
            1200: {
              items: data.items_column0,
            },
            1650: {
              items: data.items_column00,
            },
          },
        });
      });
    },
    viewProduct: function () {
      this.$router.push("product");
    },
    parentModalTrigger(pid) {
      this.$parent.parentModalTrigger(pid);
    },
    haddleFlashDeal: function () {
      this.$router.push("flashDeal");
    },
  },
};
</script>
<style scoped>
.modtitle {
  border-bottom: 2px solid #eee;
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: 0;
  margin-bottom: 10px;
}
.mobileflsh-viewall{
  width: 100%;
  padding-top: 40px;
  padding-bottom: 10px;
  display: none;
  text-align: right;
}
.mobileflsh-viewall a{
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .desktopflsh-viewall{
    display: none;
  }
  .mobileflsh-viewall{
    display: block;
  }
}
@media screen and (max-width: 550px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    font-size: 14px;
  }
}
@media screen and (max-width: 475px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    font-size: 12px;
  }

}
@media screen and (max-width: 410px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    font-size: 9px;
  }

}
@media screen and (max-width: 355px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    font-size: 7px;
  }
}
@media screen and (max-width: 320px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    margin-bottom: 15px;
  }
}
</style>
