<template>
  <div>
    <div class="item">
      <div
        v-bind:class="
          noslide
            ? 'product-layout dealProductContainer col-lg-2 col-md-4 col-sm-6 col-xs-12'
            : 'item-inner product-layout transition product-grid'
        "
      >
        <div class="product-item-container product-grid4">
          <div class="left-block left-b">
            <div class="box-label">
              <span class="label-product label-sale"
                >-{{ data.discount }}%</span
              >
            </div>
            <div class="product-image-container second_img">
              <a
                @click="viewProduct(data.item_id)"
                target="_self"
                :title="data.title"
                v-if="data.imgpath.length > 1"
              >
                <img
                  v-for="(imagepath, index) in data.imgpath.slice(0, 2)"
                  :key="index"
                  :src="imagepath"
                  :class="'img-responsive img-' + parseInt(index + 1)"
                  style="height: 180px; width: 180px; object-fit: cover"
                />
              </a>
              <a
                @click="viewProduct(data.item_id)"
                target="_self"
                :title="data.title"
                v-if="data.imgpath.length === 1"
              >
                <img
                  v-for="index in 2"
                  :key="index"
                  :src="data.imgpath[0]"
                  :class="'img-responsive img-' + parseInt(index + 1)"
                  style="height: 180px; width: 180px; object-fit: cover"
                />
              </a>
            </div>
            <!--quickview-->
            <!-- <div class="so-quickview">                   
                    <a @click="viewProduct(data.item_id)" class="btn-button quickview quickview_handler visible-lg"><i class="fa fa-eye"></i><span>Quick view</span></a>
                </div>                                                      -->
            <!--end quickview-->
          </div>
          <div class="right-block">
            <div class="button-group so-quickview cartinfo--left">
              <!-- <button @click="addToCart(data)" type="button" class="addToCart" title="Add to cart">
                        <span>Add to cart </span>   
                    </button> -->
              <button
                @click="quickViewProductModal(data.item_id)"
                type="button"
                class="addToCart"
                title="Add to cart"
              >
                <span>Add to cart </span>
              </button>
              <button
                v-if="data.wishlit === false"
                type="button"
                class="wishlist btn-button"
                title="Add to Wish List"
                @click="addToWishList(data, data.item_id)"
              >
                <i class="fa fa-heart-o"></i><span>Add to Wish List</span>
              </button>
              <button
                v-if="data.wishlit === true"
                type="button"
                class="wishlist btn-button"
                style="color: #fff; background-color: #005baa"
                title="Remove to Wish List"
                @click="removeFromWishList(data, data.item_id)"
              >
                <i class="fa fa-heart-o"></i>
              </button>
              <!-- <button type="button" class="compare btn-button" title="Compare this Product "><i class="fa fa-retweet"></i><span>Compare this Product</span>
                    </button> -->
            </div>
            <div class="caption hide-cont">
              <div class="ratings">
                <div class="rating-box">
                  <span class="fa fa-stack"
                    ><i
                      :class="[
                        data.rating >= 1 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i
                  ></span>
                  <span class="fa fa-stack"
                    ><i
                      :class="[
                        data.rating >= 2 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i
                  ></span>
                  <span class="fa fa-stack"
                    ><i
                      :class="[
                        data.rating >= 3 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i
                  ></span>
                  <span class="fa fa-stack"
                    ><i
                      :class="[
                        data.rating >= 4 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i
                  ></span>
                  <span class="fa fa-stack"
                    ><i
                      :class="[
                        data.rating >= 5 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i
                  ></span>
                </div>
                <!-- <span v-if="data.rating != undefined" class="rating-num">( {{data.rating}} )</span> -->
              </div>
              <h4
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                <a
                  @click="viewProduct(data.item_id)"
                  :title="data.title"
                  target="_self"
                  >{{ data.title }}</a
                >
              </h4>
            </div>
            <p class="price">
              <span class="price-new">Rs.{{ data.price }}</span
              >&nbsp;&nbsp;
              <span class="price-old" v-if="data.prediscount != ''"
                >Rs.{{ data.prediscount }}</span
              >
            </p>
            <div class="item-available">
              <div
                style="
                  background-color: #eee;
                  height: 13px;
                  width: 100%;
                  display: inline-block;
                  border-radius: 7px;
                  position: relative;
                  z-index: 2;
                  overflow: hidden;
                "
              >
                <span
                  class="color_width"
                  data-title=""
                  data-toggle="tooltip"
                  :style="SoldPresentage"
                  style="
                    background: #005baa;
                    position: absolute;
                    height: 100%;
                    left: 0;
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                  "
                ></span>
              </div>
              <p class="price" style="text-align: center">
                Sold: <span class="price-new">{{ data.sold_amount }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <modal ref="modal"></modal>    -->
    </div>
  </div>
</template>
<script>
import modal from "@/components/product/quick_view_product_modal.vue";
import APIProduct from "@/mixins/buyer/APIProduct";

export default {
  name: "ProductTile",
  mixins: [APIProduct],
  props: {
    data: {
      type: Object,
    },
    noslide: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      cartProductIdArray: [],
      idObj: {
        ids: [],
      },
      SoldPresentage: {
        width: "",
      },
    };
  },
  components: { modal },
  watch: {
    data: {
      handler: function (val) {
        if (val) {
          this.SoldPresentage.width = this.data.SoldPresentage;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    quickViewProductModal: function (pid) {
      //   this.$refs.modal.show()
      this.$parent.parentModalTrigger(pid);
      //this.viewProduct(pid);
    },
    viewProduct: function (item_id) {
      this.$router.push({ path: "product", query: { pid: item_id } });
    },
    addToCart: function (item) {
      this.idObj = JSON.parse(localStorage.getItem("cart_product_ids"));
      if (this.idObj !== null) {
        this.idObj.ids.push(item);
        localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
        this.$store.commit("setAddToCartSwitchState", {
          action: null,
          payload: this.idObj,
        });
      } else {
        let array = {
          ids: [],
        };
        array.ids.push(item);
        localStorage.setItem("cart_product_ids", JSON.stringify(array));
        this.$store.commit("setAddToCartSwitchState", {
          action: null,
          payload: array,
        });
      }
    },
    addToWishList: async function (data, item_id) {
      if (this.$store.state.buyer_accessToken === null) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Please login to add to Wishlist!",
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      } else {
        try {
          let wishlistItem = { product_id: item_id, status: 1 };
          let response = await this.saveWishliat(wishlistItem);
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Product Added to Wishlist",
            showConfirmButton: false,
            timer: 1500,
          });
          data.wishlit = true;
        } catch (error) {
          throw error;
        }
      }
    },
    removeFromWishList: async function (data, item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 2 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Removed from Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        data.wishlit = false;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
<style scoped>
.product-grid4 {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
}

@media screen and (max-width: 1400px) {
  .dealProductContainer {
    width: 33.333333% !important;
  }
}

@media screen and (max-width: 992px) {
  .dealProductContainer {
    width: 50% !important;
  }
}

@media screen and (max-width: 500px) {
  .dealProductContainer {
    width: 100% !important;
  }
}

.products-list.grid .product-layout .product-item-container,
.products-list .product-grid .product-item-container {
  margin: 30px 0 30px 8px !important;
  padding: 0;
}
</style>

